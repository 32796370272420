#invite {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

#invite .main {
    background-color: #f7f7f7;
    box-sizing: border-box;
}

#invite .cover {
    width: 100%;
    position: relative;
    top: -1px;
}

#invite .cover > img {
    width: 100%;
}

#invite .info {
    color: #333333;
    padding: 20px;
}

#invite .info .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

#invite .info .description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
}

#invite .trainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#invite .trainer-avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #232323;
    margin-right: 14px;
    font-size: 14px;
}

#invite .action-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 16px;
    padding: 20px;
}

#invite .info-warning {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 20px;
}

#invite .button {
    margin-bottom: 10px;
    border-right: 10px;
}

@media only screen and (min-width: 600px) {
    #invite .main {
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    #invite .container {
        display: flex;
        max-width: 825px;
    }

    #invite .cover {
        flex-basis: 50%;
        margin: 20px 0;
    }

    #invite .cover > img {
        width: 100%;
    }

    #invite .info {
        flex-basis: 50%;
    }
}
