@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #4A4A4A;
}

body, html, #root {
  width: 100%;
}

#invite {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

#invite .main {
    background-color: #f7f7f7;
    box-sizing: border-box;
}

#invite .cover {
    width: 100%;
    position: relative;
    top: -1px;
}

#invite .cover > img {
    width: 100%;
}

#invite .info {
    color: #333333;
    padding: 20px;
}

#invite .info .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

#invite .info .description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
}

#invite .trainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#invite .trainer-avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #232323;
    margin-right: 14px;
    font-size: 14px;
}

#invite .action-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 16px;
    padding: 20px;
}

#invite .info-warning {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 20px;
}

#invite .button {
    margin-bottom: 10px;
    border-right: 10px;
}

@media only screen and (min-width: 600px) {
    #invite .main {
        display: flex;
        justify-content: center;
        padding: 10px;
    }
    #invite .container {
        display: flex;
        max-width: 825px;
    }

    #invite .cover {
        flex-basis: 50%;
        margin: 20px 0;
    }

    #invite .cover > img {
        width: 100%;
    }

    #invite .info {
        flex-basis: 50%;
    }
}

header {
    width: 100%;
    flex: 1 1;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

header > .logo {
    width: 150px;
}

.button {
    background-color: #3E7CB9;
    color: white;
    padding: 14px 30px;
    font-size: 16px;
    border-radius: 3px;
    border: none;
    text-decoration: none;
}

#privacy {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

#privacy > footer > .copyright {
    padding: 1rem 0;
}

#privacy header {
    height: 2rem;
}

#privacy header .logo {
    height: 1rem;
}

#privacy header .profile-block {
    display: none;
}

.content-wrap {
    flex: 1 1;
    display: flex;
    overflow: auto;
    justify-content: center;
    padding: 0 1rem;
}

.content {
    max-width: 48rem;
    box-sizing: border-box;
}

.content > .body {
    padding: 2rem 0;
}

/* External styles */
[data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
}

[data-custom-class='title'], [data-custom-class='title'] * {
    font-size: 2rem !important;
    color: #4A4A4A !important;
}

[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    color: #595959 !important;
    font-size: 1rem !important;
}

[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-size: 1.2rem !important;
    color: #4A4A4A !important;
}

[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-size: 1.1rem !important;
    color: #4A4A4A !important;
}

[data-custom-class='body_text'], [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 1rem !important;
}

[data-custom-class='link'], [data-custom-class='link'] * {
    color: #3C92CA !important;
    font-size: 1rem !important;
    word-break: break-word !important;
}

