header {
    width: 100%;
    flex: 1;
    height: 60px;
    max-height: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

header > .logo {
    width: 150px;
}
