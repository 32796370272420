#privacy {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

#privacy > footer > .copyright {
    padding: 1rem 0;
}

#privacy header {
    height: 2rem;
}

#privacy header .logo {
    height: 1rem;
}

#privacy header .profile-block {
    display: none;
}

.content-wrap {
    flex: 1;
    display: flex;
    overflow: auto;
    justify-content: center;
    padding: 0 1rem;
}

.content {
    max-width: 48rem;
    box-sizing: border-box;
}

.content > .body {
    padding: 2rem 0;
}

/* External styles */
[data-custom-class='body'], [data-custom-class='body'] * {
    background: transparent !important;
}

[data-custom-class='title'], [data-custom-class='title'] * {
    font-size: 2rem !important;
    color: #4A4A4A !important;
}

[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
    color: #595959 !important;
    font-size: 1rem !important;
}

[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
    font-size: 1.2rem !important;
    color: #4A4A4A !important;
}

[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
    font-size: 1.1rem !important;
    color: #4A4A4A !important;
}

[data-custom-class='body_text'], [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 1rem !important;
}

[data-custom-class='link'], [data-custom-class='link'] * {
    color: #3C92CA !important;
    font-size: 1rem !important;
    word-break: break-word !important;
}
